import { useParams } from "react-router-dom";
import CV from "./cv.js";
import CVItem from "./CVItem.js";
import React from "react";

function CVItemList() {

    const {section} = useParams();
    const list = CV[section].map(item => <CVItem item={item}/>)
    return <div className="CVlist">{list}</div>
};

export default CVItemList;
