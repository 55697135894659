import './App.css';
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';
import Homepage from './components/homepage/Homepage.js';
import Resume from './components/resume/Resume.js';
import CVItemList from './components/resume/CVItemList.js';



const router = createBrowserRouter(createRoutesFromElements(
  <>
    <Route path='/' element={<Homepage />} />
    <Route path='/resume/' element={<Resume />}>
      <Route path='/resume/:section' element={<CVItemList/>}/>
    </Route>
  </>

))

function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
