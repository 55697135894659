const CV = {
    beruf: [
        {
            time: 'Juli 2024 - laufend',
            title: 'MVZ für Implantologie, Zahnästhetik und Zahnmedizin drs. De Jonge, Dr. Wilken DEIN DENTAL Papenburg GmbH',
            description: ['Zahnarzt', 'Allgeimeine Zahnheilkunde mit Schwerpunkt auf Oralchirurgie, Parodontologie und ästhetischer Zahnmedizin']
        },
        {
            time: 'April 2024 - Mai 2024',
            title: 'Zahnarztpraxis Zahnheilkunde Augustfehn',
            description: ['Zahnarzt', 'Allgemeine zahnärztliche Behandlung von Kassen- und Privatpatienten']
        },
        {
            time: 'Juli 2022 - März 2024',
            title: 'Zahnarztpraxis MundWerk (Dr. H. Pahl und ZA Andreas Littwin)',
            description: ['Zahnarzt', 'Allgemeine zahnärztliche Behandlung von Kassen- und Privatpatienten']
        },
        {
            time: 'Februar 2019 - April 2022',
            title: 'Private zahnärztliche Praxis «Amelia», Moskau',
            description: [
                'Oralchirurg',
                'Behandlung von Privatpatienten mit Schwerpunkt auf Implantologie'
            ]
        },
        {
            time: 'Dezember 2014 - Februar 2020',
            title: 'Staatliche zahnmedizinische Poliklinik Nr. 8, Moskau',
            description: [
                'Oralchirurg',
                'Behandlung von Kassen- und Privatpatienten, Führung der medizinischen Dokumentation'
            ]
        }
    ],
    ausbildung: [
        {
            time: 'Juli 2018 - Oktober 2019',
            title: 'M.Sc. Implantology and Dental Surgery',
            description: ['International Medical College, Münster (Westf.)']
        },
        {
            time: 'August 2014 - Dezember 2014',
            title: 'Spezialisierung als Oralchirurg',
            description: ['Weiterbildungsinstitut für Ärzte bei dem Nationalen Medizinischen Chirurgischen N.I. Pirogow-Zentrum, Moskau']
        },
        {
            time: 'September 2008 - Juli 2013',
            title: 'Studium Zahnmedizin',
            description: ['Moskauer Staatliche A.I. Jewdokimow-Universität für Medizin und Zahnmedizin']
        }
    ],
    praktika: [
        {
            time: 'Juni 2024',
            title: 'Hospitation an dem MVZ für Implantologie, Zahnästhetik und Zahnmedizin drs. De Jonge, Dr. Wilken DEIN DENTAL Papenburg GmbH',
            description: ['Kennenlernen des Teams und der Arbeitsabläufe in der Praxis']
        },
        {
            time: 'April 2022 - Juni 2022',
            title: 'Hospitation an der Zahnarztpraxis MundWerk (Dr. H. Pahl und ZA Andreas Littwin), Südbrookmerland',
            description: ['Kennenlernen des Teams und der Arbeitsabläufe in der Praxis']
        },
        {
            time: 'September 2013 - Juli 2014',
            title: 'Praktikum (Internatur) an der Moskauer Staatlichen A.I. Jewdokimow-Universität für Medizin und Zahnmedizin',
            description: ['Praktisches Jahr nach dem Studium']
        }
    ],
    weiterbildung: [
        {
            time: 'November 2022',
            title: 'Kurs im Strahlenschutz für Zahnärzte',
            description: ['HSmediStrahlenschutz, Schwerin']
        },
        {
            time: 'September 2019',
            title: 'Fortbildung "Chirurgische Zahnmedizin"',
            description: ['Private Bildungseinrichtung für zusätzliche berufliche Ausbildung "Institut für Fortbildung und moderne Technologien"']
        },
        {
            time: 'August 2017',
            title: 'Privatkurs "Sinus-Lifting"',
            description: ['Moskau']
        },
        {
            time: 'Februar 2016',
            title: 'Privatkurs "Prognostizierbare Implantation"',
            description: ['R2Gate Technologie, Moskau']
        },
        {
            time: 'Februar 2016',
            title: 'Internationale wissenschaftlich-praktische Konferenz VII "Maxillofaziale Rehabilitation"',
            description: ['Moskau']
        },
        {
            time: 'Dezember 2015',
            title: 'Privatkurs für fortgeschnittene "Methoden der Knochenplastische Chirurgie, Sinus-Lifting, Alveolar Ridge Splitting"',
            description: ['Moskau']
        },
        {
            time: 'September 2015',
            title: 'Privatkurs "Implantationsgrundgurs"',
            description: ['Moskau']
        },

    ],
    kenntnisse: [
        {
            title: 'Sprachen',
            description: ['Deutsch', 'Englisch', 'Russich']
        },
        {
            title: 'IT',
            description: ['Microsoft Office', 'iCATVision', 'Vatech Ez3d', 'Sirona Galileos', 'charly: Die Zahnarztsoftware', 'Z1: die Zahnarztsoftware']
        }
    ],
    hobbies: [
        {
            title: 'Entspannender Death-Metal',
            description: ['Frohe mich immer auf Metal-Konzerte und Festivals']
        },
        {
            title: 'Autos',
            description: ['Immer auf der suche nach dem perfektem Fahrzeug']
        },
        {
            title: 'Video-Spiele',
            description: ['Spiele in letzter Zeit eher selten, aber halte mich auf dem Laufenden über die Neuigkeiten']
        },
        {
            title: 'Skifahren',
            description: ['Vermisse schon den Urlaub in den Bergen']
        }
    ]
};

export default CV;