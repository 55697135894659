import React from "react";
import Contact from './Contact.js';
import About from './About.js';

function Homepage() {
  return (
    <div className="homepage">
        <Contact />
        <About />
    </div>
  )
};

export default Homepage;