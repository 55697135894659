import React from "react";
import { NavLink } from "react-router-dom";
import { DownloadLink } from "./DownloadButton";

function CVSectionList() {
    const styling = ({ isActive }) => {
        return isActive ? { color: "black", backgroundColor: "white" } : {};
    }

    return <div className="sectionList">
        <NavLink to='./beruf' className="link section" style={styling}>Berufserfahrung</NavLink>
        <NavLink to='./ausbildung' className="link section" style={styling}>Ausbildung</NavLink>
        <NavLink to='./praktika' className="link section" style={styling}>Praktika</NavLink>
        <NavLink to='./weiterbildung' className="link section" style={styling}>Weiterbildung</NavLink>
        <NavLink to='./kenntnisse' className="link section" style={styling}>Sonstige Kenntnisse</NavLink>
        <NavLink to='./hobbies' className="link section" style={styling}>Interessen & Hobbies</NavLink>
        <DownloadLink url='../../Lebenslauf.pdf' fileName='lebenslauf.pdf' className="download">Als PDF herunterladen</DownloadLink>
    </div>
};

export default CVSectionList;
