import React from "react";

function CVItem({item}) {
    return <div className="CVitem">
        <p className="time">{item.time}</p>
        <h2 className="title">{item.title}</h2>
        {item.description.map(part => <p className="description">{part}</p>)}
    </div>
};

export default CVItem;
