import React from "react";

export const DownloadLink = ({ url, fileName }) => {
    const handleDownload = () => {
        // You can either trigger download by programmatically clicking a hidden link
        const link = document.createElement('a');
        link.href = '/Lebenslauf.pdf'; // Path to your PDF file in the public folder
        link.download = 'Smirnov_Lebenslauf.pdf'; // This will be the file name when the user downloads
        link.click();
    };

    return (
        <div className="download">
            <button onClick={handleDownload} className="link">
                Lebenslauf als PDF herunterladen
            </button>
        </div>
    );
};