import React from "react";
import { Link } from "react-router-dom";

function Contact() {
  return <div className="contact">
    <img src='/foto.jpg' className="profilePhoto" alt="profile"/>
    <h1 className="name">Roman Smirnov</h1>
    <h2 className="title">M.Sc. Implantology and Dental Surgery</h2>
    <Link to='#'
        onClick={(e) => {
            window.location.href = "mailto:dentist.roman.smirnov@gmail.com";
            e.preventDefault();
        }}
        className="link contactLink">
            E-Mail
    </Link>
    <Link to='https://www.xing.com/profile/Roman_Smirnov6' className="link contactLink">XING</Link>
  </div>
};

export default Contact;