import React from "react";
import { Link } from "react-router-dom";

function About() {
    return (
        <div className="about">
            <h1 className="aboutMe">Über mich</h1>
            <p>Ich bin ein Zahnarzt mit Leidenschaft für Oralchirurgie, Implantologie und Parodontologie. 
                Seit über 11 Jahren Berufspraxis habe ich ein tiefes Verständnis für präzise chirurgische Eingriffe und moderne 
                Zahnimplantattechniken entwickelt und biete umfassende, individuelle Behandlungen, 
                die Funktionalität und Ästhetik vereinen. Mein Ziel ist es, Patienten nicht 
                nur eine optimale zahnmedizinische Versorgung zu bieten, sondern auch ihr 
                Vertrauen und Wohlbefinden während jeder Behandlung sicherzustellen.</p>
            <Link to='/resume/beruf' className="link learnMore">Mehr erfahren</Link>
        </div>
    )
};

export default About;