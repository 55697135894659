import React from "react";
import { Link, Outlet } from "react-router-dom";
import CVSectionList from "./CVSectionList";


function Resume() {

    return (
        <div className="resume">
            <Link to='/' className="start">Startseite</Link>
            <CVSectionList />
            <Outlet />
            
        </div>
    )
};

export default Resume;